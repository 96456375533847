import React from 'react'
import { devMenuItems } from './constants/devMenuItems'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import './App.css'
import './core/typography.css'
import './core/buttons.css'
import './core/ejicon.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import AboutUs from './pages/us/AboutUs'
import Settings from './pages/settings/Settings'
import Profile from './pages/profile/Profile'
import Partners from './pages/partners/Partners'
import NewsDetail from './pages/news_detail/NewsDetail'
import Mentors from './pages/mentors/Mentors'
import Events from './pages/events/Events'
import Dashboard from './pages/dashboard/Dashboard'
import CourseList from './pages/course_list/CourseList'
import CourseSlide from './pages/course_slide/CourseSlide'
import CourseDetail from './pages/course_detail/CourseDetail'
import News from './pages/news/News'
import Home from './pages/home/Home'
import Login from './pages/login_reg/Login'
import Registration from './pages/login_reg/Registration'
import ForgottenPassword from './pages/login_reg/ForgottenPassword'
import PasswordReset from './pages/login_reg/PasswordReset'
import { decodeToken } from 'react-jwt'
import authProvider from './authProvider'
import dataProvider from './dataProvider'
import AppModal from './components/modal/AppModal'
import ScrollToTop from './components/header/ScrollToTop'
import NotFound from './pages/notfound/NotFound'
import Landing from './pages/landing/Landing'

interface user_profile {
	lastName: string
	firstName: string
	age: string
	gender: string
	region: string
	education: string
	jobPresent: boolean
	jobInPast: boolean
	email: string
	acceptEmails: boolean
	emailVerifiedAt?: Date | null
	profilePictureUrl?: string | null
	lastSchool?: string | null
}

const emptyProfile: user_profile = {
	lastName: '',
	firstName: '',
	age: '',
	gender: 'NA',
	region: '',
	education: '',
	jobPresent: false,
	jobInPast: false,
	email: '',
	acceptEmails: false,
	emailVerifiedAt: null,
	profilePictureUrl: null,
	lastSchool: null,
}

function App() {
	/* accessToken felvétele state-ba, azonnali frissítéshez */
	const [accessToken, setAccessToken] = React.useState(
		localStorage.getItem('access_token'),
	)
	const [userProfile, setUserProfile] = React.useState(emptyProfile)
	const [courseList, setCourseList] = React.useState([])
	const [eventList, setEventList] = React.useState([])
	const [showModal, setShowModal] = React.useState(false)
	const [modalComponent, setModalComponent] =
		React.useState<JSX.Element | null>(null)
	const [textNews, setTextNews] = React.useState<any[]>([])
	const [videoNews, setVideoNews] = React.useState<any[]>([])

	const refreshUserProfile = (): void => {
		dataProvider.getProfile((userData: any) => {
			setUserProfile(userData.data.data || [])
		})
	}

	const refreshNews = (): void => {
		dataProvider.getMany('/news', (newsData: any) => {
			try {
				const { textNews, videoNews } = newsData?.data?.data
				setTextNews(textNews || [])
				setVideoNews(videoNews || [])
			} catch (error) {
				console.error(error)
			}
		})
	}

	const refreshCourseList = (): void => {
		dataProvider.getMany('/courses', (courseListData: any) => {
			setCourseList(courseListData?.data?.data || [])
		})
	}

	const refreshEventList = (): void => {
		dataProvider.getMany('/events', (eventListData: any) => {
			setEventList(eventListData?.data?.data || [])
		})
	}

	const modalPopper = (component: JSX.Element | null) => {
		setModalComponent(component)
		setShowModal(true)
	}

	React.useEffect(() => {
		/* access token frissítése 1 mp-el a lejárat előtt vagy, ha már lejárt azonnal */
		let tokenRefresher: NodeJS.Timeout | null = null
		refreshCourseList()
		refreshEventList()
		refreshNews()
		if (accessToken) {
			const decodedToken: any = decodeToken(accessToken)
			try {
				const expDateMinusOneSec = decodedToken.exp * 1000 - 1000
				const timeNow = new Date().getTime()
				if (expDateMinusOneSec - timeNow < 0) {
					authProvider.refreshToken((newToken: string | null) => {
						setAccessToken(newToken)
						/* get stack */
						dataProvider.getProfile((userData: any) => {
							console.log('APP 97')
							try {
								setUserProfile(userData.data.data)
							} catch (error) {
								console.error(error)
							}
						})
					})
					console.log('token outdated')
				} else {
					tokenRefresher = setTimeout(() => {
						authProvider.refreshToken((newToken: string | null) =>
							setAccessToken(newToken),
						)
						console.log('token refreshed')
					}, expDateMinusOneSec - timeNow)
					/*  get stack */
					dataProvider.getProfile((userData: any) => {
						console.log('App 113')

						try {
							setUserProfile(userData.data.data)
						} catch (error) {
							console.error(error)
						}
					})
				}
			} catch (error) {
				console.error(error)
				setAccessToken(null)
			}
		} else {
			if (tokenRefresher) {
				clearTimeout(tokenRefresher)
			}
			setUserProfile(emptyProfile)
		}

		/* async függvény törlése, ha unmountol a komponens,
    igazából nem kell, mert ebben az esetben, kilépnek a programból */
		return tokenRefresher ? clearTimeout(tokenRefresher) : () => {}
	}, [accessToken])

	const routeDetails: Array<any> = [
		{
			path: '/elfelejtett_jelszo',
			component: (
				<ForgottenPassword
					modalPopper={modalPopper}
					closeModal={() => setShowModal(false)}
				/>
			),
		},
		{
			path: '/regisztracio',
			component: (
				<Registration
					modalPopper={modalPopper}
					closeModal={() => setShowModal(false)}
				/>
			),
		},
		{
			path: '/bejelentkezes',
			component: (
				<Login
					setAccessToken={setAccessToken}
					modalPopper={modalPopper}
					closeModal={() => setShowModal(false)}
				/>
			),
		},
		/*  {
       path: '/alkosa',
       component: <Alkosa />
     },
     {
       path: '/albasa',
       component: <Albasa />
     },
     {
       path: '/alhole',
       component: <Alhole modalPopper={modalPopper} />
     }, */
		{
			path: '/',
			component: (
				<Home
					courseList={courseList}
					refreshCourseList={refreshCourseList}
				/>
			),
		},
		{
			path: '/hirek',
			component: (
				<News
					eventList={eventList}
					refreshEventList={refreshEventList}
					textNews={textNews}
					videoNews={videoNews}
				/>
			),
		},

		/* {
      path: '/hirek/mi_var_rad_az_enjovom_oldalan',
      component: <News1
        eventList={eventList}
        refreshEventList={refreshEventList} />
    },
    {
      path: '/hirek/ismerd_meg_partnereinket_azenjovom_podcast_epizodjaibol',
      component: <News2
        eventList={eventList}
        refreshEventList={refreshEventList} />
    },
    {
      path: '/hirek/mi_varhato_az_enjovom_2_feleveben',
      component: <News3
        eventList={eventList}
        refreshEventList={refreshEventList} />
    }, */

		{
			path: '/hirek/:id',
			component: (
				<NewsDetail
					textNews={textNews}
					eventList={eventList}
					refreshEventList={refreshEventList}
				/>
			),
		},
		{
			path: '/tananyagok/:id',
			component: (
				<CourseDetail
					userProfile={userProfile}
					courseList={courseList}
					modalPopper={modalPopper}
					closeModal={() => setShowModal(false)}
					refreshCourseList={refreshCourseList}
				/>
			),
		},
		{
			path: '/tananyag_diak/:id',
			component: <CourseSlide />,
		},
		{
			path: '/tananyagok',
			component: (
				<CourseList
					courseList={courseList}
					refreshCourseList={refreshCourseList}
					eventList={eventList}
					refreshEventList={refreshEventList}
				/>
			),
		},
		{
			path: '/onfejlesztes',
			component: (
				<CourseList
					courseList={courseList}
					refreshCourseList={refreshCourseList}
					eventList={eventList}
					refreshEventList={refreshEventList}
					selectedFilter="önismeret és önfejlesztés"
				/>
			),
		},
		{
			path: '/fenntarthatosag',
			component: (
				<CourseList
					courseList={courseList}
					refreshCourseList={refreshCourseList}
					eventList={eventList}
					refreshEventList={refreshEventList}
					selectedFilter="fenntarthatóság"
				/>
			),
		},
		{
			path: '/kezelopult',
			component: (
				<Dashboard
					userProfile={userProfile}
					courseList={courseList}
					eventList={eventList}
					refreshCourseList={refreshCourseList}
				/>
			),
		},
		{
			path: '/esemenyek',
			component: (
				<Events
					eventList={eventList}
					refreshEventList={refreshEventList}
					modalPopper={modalPopper}
					closeModal={() => setShowModal(false)}
				/>
			),
		},
		{
			path: '/esemenyek/:slug',
			component: (
				<Events
					eventList={eventList}
					refreshEventList={refreshEventList}
				/>
			),
		},
		{
			path: '/profile/password-reset:query',
			component: (
				<PasswordReset
					modalPopper={modalPopper}
					closeModal={() => setShowModal(false)}
				/>
			),
		},
		{
			path: '/mentorok',
			component: (
				<Mentors
					modalPopper={modalPopper}
					closeModal={() => setShowModal(false)}
					userProfile={userProfile}
				/>
			),
		},

		{
			path: '/partnerek',
			component: (
				<Partners
					modalPopper={modalPopper}
					closeModal={() => setShowModal(false)}
					userProfile={userProfile}
				/>
			),
		},
		{
			path: '/profil',
			component: (
				<Profile
					userProfile={userProfile}
					setUserProfile={setUserProfile}
					courseList={courseList}
					refreshCourseList={refreshCourseList}
					eventList={eventList}
					modalPopper={modalPopper}
					closeModal={() => setShowModal(false)}
				/>
			),
		},
		{
			path: '/beallitasok',
			component: (
				<Settings
					modalPopper={modalPopper}
					closeModal={() => setShowModal(false)}
				/>
			),
		},
		{
			path: '/rolunk',
			component: (
				<AboutUs
					modalPopper={modalPopper}
					closeModal={() => setShowModal(false)}
					userProfile={userProfile}
				/>
			),
		},
		{
			path: '/:slug',
			component: <Landing />,
		},
		{
			component: <NotFound />,
		},
	]

	const [isDarkMode, setDarkMode] = React.useState(
		localStorage.getItem('DarkMode') === '1',
	)

	React.useEffect(() => {
		const body = document.body

		if (isDarkMode) {
			body.classList.add('dark-mode')
			body.classList.remove('light-mode')
		} else {
			body.classList.add('light-mode')
			body.classList.remove('dark-mode')
		}
	}, [isDarkMode])

	const toggleDarkMode = () => {
		setDarkMode((val) => {
			localStorage.setItem('DarkMode', val ? '0' : '1')

			const body = document.body

			if (!val) {
				body.classList.add('dark-mode')
				body.classList.remove('light-mode')
			} else {
				body.classList.add('light-mode')
				body.classList.remove('dark-mode')
			}

			return !val
		})
	}

	return (
		<div className={'App ' + (isDarkMode ? 'dark-mode' : 'light-mode')}>
			<Router>
				<Header
					accessToken={accessToken}
					userProfile={userProfile}
					setAccessToken={setAccessToken}
					toggleDarkMode={toggleDarkMode}
					isDarkMode={isDarkMode}
				/>
				<div className="margin_top">
					<div className="temp_menu">
						<ul style={{ display: 'flex', listStyleType: 'none' }}>
							{devMenuItems.map((menuIttem) => (
								<li
									style={{ marginLeft: '5px' }}
									key={menuIttem.path}
								></li>
							))}
						</ul>
					</div>
					<ScrollToTop />
					<Switch>
						{routeDetails.map((menuItem) => (
							<Route
								exact
								path={menuItem.path}
								key={menuItem.path + 'route'}
							>
								{menuItem.component}
							</Route>
						))}
						{/* <Route path='/profile/password-reset:query' key='pswd-reset'>
              <PasswordReset />
            </Route> */}
					</Switch>
				</div>
			</Router>
			<Footer
				modalPopper={modalPopper}
				closeModal={() => setShowModal(false)}
				userProfile={userProfile}
			/>
			<AppModal
				show={showModal}
				onHide={() => setShowModal(false)}
				children={modalComponent}
			/>
		</div>
	)
}

export default App
